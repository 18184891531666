import React, {useEffect, useState} from 'react';
import Box from './Box';


function Counter(props)
{
  
    const calculateTimeLeft = () => 
    {
        let year = new Date().getFullYear();
        const difference = +new Date(props.final) - +new Date();

        let timeLeft = {};

        if (difference > 0) 
        {
            timeLeft = {
              day: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hour: Math.floor((difference / (1000 * 60 * 60)) % 24),
              minute: Math.floor((difference / 1000 / 60) % 60),
              second: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    }
          

    const [time, settime] = useState( calculateTimeLeft );

    useEffect(() => {
        
        const timer = setTimeout(() => {

          settime(calculateTimeLeft());

        }, 1000);

        return () => clearTimeout(timer);

    }, [time]);


    return(
        <>
            <Box time={time.day} title={"Dias"} />
            <Box time={time.hour} title={"Horas"} />
            <Box time={time.minute} title={"Minutos"} />
            <Box time={time.second} title={"Segundos"} />  
        </>
    )
}

export default Counter;