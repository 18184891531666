import {React, useEffect, useState} from 'react';

function Box(props)
{

    return(

        <div>
            <div>
                <h1>{props.time}</h1>
            </div>
            <div>
                <h6>{props.title}</h6>
            </div>
        </div>

    )

}

export default Box;