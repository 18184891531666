import Content from '../components/Content';

function Web()
{
    return(
        <header className="revb-wrapper revb-fade">
            <Content/>
            <div>
                <img className="revb-cons-img" src="https://revistabomberos.pe/imagenes/back.jpg" />
            </div>
        </header>
    )
}

export default Web;