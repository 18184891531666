import Text from '../components/Text';
import Effect from '../components/Effect';
import Contact from '../components/Contact';
import Counter from'../components/Counter';

function Content()
{

    const info = "La Revista de Bomberos del Perú, dentro de sus objetivos institucionales brinda a la ciudadanía importante información relacionada a la prevención y seguridad ciudadana. orientando nuestros esfuerzos a establecer una cultura de seguridad preventiva en beneficio de todos los peruanos.";

    return(

        <>
            <div>
                <div className="revb-container-logo">
                    <img src="https://revistabomberos.pe/imagenes/logo.png" alt="Revista Bomberos Perú" />
                </div>
            <div>

            <Text 
                title={ "Sitio en construcción" }
                info={ info }
            />

            <Effect />

            <div className="revb-counter">
                <Counter final={'10/30/2022'}/>  
            </div>
        </div>

            <Contact/>         

        </div>
        </>

    )

}

export default Content;