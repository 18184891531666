import React from 'react';
import Dot from '../images/Dot';

function Effect()
{
    return(
        <>
            <div className="revb-circle"><div><div></div></div></div>
            <div className="revb-dots"><div><Dot/></div></div>
        </>
    )
    
}

export default Effect;