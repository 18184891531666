import Web from './Views/Web';
import Intro from './components/Intro';
import './styles/App.scss';

function App() 
{
  return (
    <div className="App">
      {/* <Intro/> */}
      <Web/>
    </div>
  );
}

export default App;
