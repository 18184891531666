import React from 'react';

function Dot()
{

    return(

        <svg width={10+'rem'} id="dots" data-name="dots" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 648.78 270.73">
            <path style={{fill: '#dcb36a'}} d="M44.5,46.26a9.84,9.84,0,1,1-9.84-9.83A9.84,9.84,0,0,1,44.5,46.26Z"/>
            <path style={{fill: '#dcb36a'}} d="M116.16,46.26a9.84,9.84,0,1,1-9.83-9.83A9.83,9.83,0,0,1,116.16,46.26Z"/>
            <path style={{fill: '#dcb36a'}} d="M187.82,46.26A9.84,9.84,0,1,1,178,36.43,9.83,9.83,0,0,1,187.82,46.26Z"/>
            <path style={{fill: '#dcb36a'}} d="M259.49,46.26a9.84,9.84,0,1,1-9.84-9.83A9.84,9.84,0,0,1,259.49,46.26Z"/>
            <path style={{fill: '#dcb36a'}} d="M331.15,46.26a9.84,9.84,0,1,1-9.84-9.83A9.84,9.84,0,0,1,331.15,46.26Z"/>
            <path style={{fill: '#dcb36a'}} d="M402.81,46.26A9.84,9.84,0,1,1,393,36.43,9.83,9.83,0,0,1,402.81,46.26Z"/>
            <path style={{fill: '#dcb36a'}} d="M474.48,46.26a9.84,9.84,0,1,1-9.84-9.83A9.84,9.84,0,0,1,474.48,46.26Z"/>
            <path style={{fill: '#dcb36a'}} d="M44.5,108.09a9.84,9.84,0,1,1-9.84-9.83A9.85,9.85,0,0,1,44.5,108.09Z"/>
            <path style={{fill: '#dcb36a'}} d="M116.16,108.09a9.84,9.84,0,1,1-9.83-9.83A9.84,9.84,0,0,1,116.16,108.09Z"/>
            <path style={{fill: '#dcb36a'}} d="M187.82,108.09A9.84,9.84,0,1,1,178,98.26,9.84,9.84,0,0,1,187.82,108.09Z"/>
            <path style={{fill: '#dcb36a'}} d="M259.49,108.09a9.84,9.84,0,1,1-9.84-9.83A9.85,9.85,0,0,1,259.49,108.09Z"/>
            <path style={{fill: '#dcb36a'}} d="M331.15,108.09a9.84,9.84,0,1,1-9.84-9.83A9.85,9.85,0,0,1,331.15,108.09Z"/>
            <path style={{fill: '#dcb36a'}} d="M402.81,108.09A9.84,9.84,0,1,1,393,98.26,9.84,9.84,0,0,1,402.81,108.09Z"/>
            <path style={{fill: '#dcb36a'}} d="M474.48,108.09a9.84,9.84,0,1,1-9.84-9.83A9.85,9.85,0,0,1,474.48,108.09Z"/>
            <path style={{fill: '#dcb36a'}} d="M44.5,169.92a9.84,9.84,0,1,1-9.84-9.84A9.84,9.84,0,0,1,44.5,169.92Z"/>
            <path style={{fill: '#dcb36a'}} d="M116.16,169.92a9.84,9.84,0,1,1-9.83-9.84A9.83,9.83,0,0,1,116.16,169.92Z"/>
            <path style={{fill: '#dcb36a'}} d="M187.82,169.92a9.84,9.84,0,1,1-9.83-9.84A9.83,9.83,0,0,1,187.82,169.92Z"/>
            <path style={{fill: '#dcb36a'}} d="M259.49,169.92a9.84,9.84,0,1,1-9.84-9.84A9.84,9.84,0,0,1,259.49,169.92Z"/>
            <path style={{fill: '#dcb36a'}} d="M331.15,169.92a9.84,9.84,0,1,1-9.84-9.84A9.84,9.84,0,0,1,331.15,169.92Z"/>
            <path style={{fill: '#dcb36a'}} d="M402.81,169.92a9.84,9.84,0,1,1-9.83-9.84A9.83,9.83,0,0,1,402.81,169.92Z"/>
            <path style={{fill: '#dcb36a'}} d="M474.48,169.92a9.84,9.84,0,1,1-9.84-9.84A9.84,9.84,0,0,1,474.48,169.92Z"/>
            <path style={{fill: '#dcb36a'}} d="M44.5,231.74a9.84,9.84,0,1,1-9.84-9.83A9.84,9.84,0,0,1,44.5,231.74Z"/>
            <path style={{fill: '#dcb36a'}} d="M116.16,231.74a9.84,9.84,0,1,1-9.83-9.83A9.83,9.83,0,0,1,116.16,231.74Z"/>
            <path style={{fill: '#dcb36a'}} d="M187.82,231.74a9.84,9.84,0,1,1-9.83-9.83A9.83,9.83,0,0,1,187.82,231.74Z"/>
            <path style={{fill: '#dcb36a'}} d="M259.49,231.74a9.84,9.84,0,1,1-9.84-9.83A9.84,9.84,0,0,1,259.49,231.74Z"/>
            <path style={{fill: '#dcb36a'}} d="M331.15,231.74a9.84,9.84,0,1,1-9.84-9.83A9.84,9.84,0,0,1,331.15,231.74Z"/>
            <path style={{fill: '#dcb36a'}} d="M402.81,231.74a9.84,9.84,0,1,1-9.83-9.83A9.83,9.83,0,0,1,402.81,231.74Z"/>
            <path style={{fill: '#dcb36a'}} d="M474.48,231.74a9.84,9.84,0,1,1-9.84-9.83A9.84,9.84,0,0,1,474.48,231.74Z"/>
            <path style={{fill: '#dcb36a'}} d="M545.74,46.26a9.84,9.84,0,1,1-9.84-9.83A9.84,9.84,0,0,1,545.74,46.26Z"/>
            <path style={{fill: '#dcb36a'}} d="M617.4,46.26a9.84,9.84,0,1,1-9.84-9.83A9.83,9.83,0,0,1,617.4,46.26Z"/>
            <path style={{fill: '#dcb36a'}} d="M545.74,108.09a9.84,9.84,0,1,1-9.84-9.83A9.85,9.85,0,0,1,545.74,108.09Z"/>
            <path style={{fill: '#dcb36a'}} d="M617.4,108.09a9.84,9.84,0,1,1-9.84-9.83A9.84,9.84,0,0,1,617.4,108.09Z"/>
            <path style={{fill: '#dcb36a'}} d="M545.74,169.92a9.84,9.84,0,1,1-9.84-9.84A9.84,9.84,0,0,1,545.74,169.92Z"/>
            <path style={{fill: '#dcb36a'}} d="M617.4,169.92a9.84,9.84,0,1,1-9.84-9.84A9.83,9.83,0,0,1,617.4,169.92Z"/>
            <path style={{fill: '#dcb36a'}} d="M545.74,231.74a9.84,9.84,0,1,1-9.84-9.83A9.84,9.84,0,0,1,545.74,231.74Z"/>
            <path style={{fill: '#dcb36a'}} d="M617.4,231.74a9.84,9.84,0,1,1-9.84-9.83A9.83,9.83,0,0,1,617.4,231.74Z"/>
        </svg>

    );

}

export default Dot;