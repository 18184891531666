import React from 'react';

function Text(props)
{

    return(
        <div>
            <div>
                <h1>{props.title}</h1>
            </div>

            <article>
                <p>{props.info}</p>
            </article>
        </div>
    )
}

export default Text;